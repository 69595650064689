import * as React from "react";
import { Helmet } from "react-helmet";
import { withPrefix, Link } from "gatsby";

import VideoBg from "../components/videoBg";

import { FRONT_DOMAIN } from "../config";

import "../scss/main.scss";
import 'swiper/css';
import PageHeader from "../components/pageHeader";
import PageSubHeader   from "../components/pageSubHeader";
import CaseTestimonials from "../components/caseTestimonials";
import CaseSwitcher from "../components/caseSwitcher";
import CaseHeroImage from "../components/caseHeroImage";
import MuseImage0 from "../img/cases/muse/image_0.png";
import MuseImage from "../img/cases/muse/preview.png";
import CaseImageSlide from "../components/caseImageSlide";
import MuseImage21 from "../img/cases/muse/image_2_1.png";
import CaseImagesBlock from "../components/caseImagesBlock";
import MuseImage31 from "../img/cases/muse/image_3_1.png";
import MuseImage32 from "../img/cases/muse/image_3_2.png";

const MuseCase = () => {

    return (
        <>
            <Helmet>
                <title>Digital Muse | Works | Teleport</title>
                <script src={withPrefix('inobounce.js')}></script>
                <meta property="og:image" content={`${FRONT_DOMAIN}/img/share_default.jpg`}/>
                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
                <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"
                      rel="stylesheet"/>
            </Helmet>

            <VideoBg/>

            <div className="site-section__about-top-background"></div>

            <section id="main-section" className="site-section">
                <PageHeader
                    annotationText={"@ Cloudera EVOLVE Conference 2024"}
                    titleText={"Digital Muse"}
                    subTitleText={"Bringing back the classic arts to you through \n" +
                        "interactive AI-driven technology"}
                />
                <CaseTestimonials testimonials={['Artificial intelligence', 'Art installation', 'NFT Art']}/>
                <CaseHeroImage image={MuseImage0} imageALT="Digital Muse"/>
                <CaseImageSlide image={MuseImage21} text="Digital Muse provides a self-service digital renaissance experience where participants interact with an AI artist within a dynamic digital frame.<br><br>The AI captures their portrait and morphs it into a chosen artistic style, celebrating the diverse global heritage of artistry. Inspired by renowned artists, Digital Muse adds a futuristic twist to their styles and themes, creating unique, modern interpretations of classic art."/>
                <PageSubHeader text="The installation was bringing back these amazing artists through interactive AI-powered technology. Each character is programmed to think, speak, and create as if they were alive today. "/>
                <PageSubHeader text="These characters are not merely replicating styles but use modern tools to reinterpret how they would behave existing in our world today. They craft unique, personalised portraits that blend the essence of their original genius with the possibilities of contemporary technology and modern context."/>
                <CaseImagesBlock images={[MuseImage31, MuseImage32]}/>
                <CaseSwitcher previousLink="/works/emotive-clouds-show/" previousName="The Emotive Clouds Show" nextLink="/works/prism/" nextName="Prism"/>
            </section>

        </>
    )
}

export default MuseCase;
